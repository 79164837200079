import {FormControlLabel, Stack, Switch, TextField} from "@mui/material";
import React from "react";
import {FieldInputProps, FormikErrors, FormikTouched} from "formik/dist/types";
import {FormikValues} from "formik";

const CURRENCIES_LIST = [
    {"code": "EUR", "name": "Euro"},
    {"code": "USD", "name": "United States dollar"},
    {"code": "CHF", "name": "Swiss Franc"},
    {"code": "GBP", "name": "British Pound"},
];

export type Props = {
    getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<FormikErrors<FormikValues>> | Promise<void>;
    errors: FormikErrors<FormikValues>;
    touched: FormikTouched<any>;
    fieldsName: string
    editing: boolean
};
export default function PaymentLinkInputForm({getFieldProps, touched, errors, fieldsName, editing, setFieldValue}: Props){


    const name = getFieldProps(`${fieldsName}.name`).value
    const description = getFieldProps(`${fieldsName}.description`).value;

    return(
        <Stack
            direction={{ xs: 'column', sm: 'column' }}
            spacing={2}
            sx={{ justifyContent: 'space-between' }}
        >
            <TextField
                fullWidth
                label="Total Amount"
                placeholder={"Enter total amount in selected currency"}
                {...getFieldProps(`${fieldsName}.unitAmount`)}
                error={Boolean(
                    // @ts-ignore
                    touched?.[fieldsName]?.unitAmount &&
                    // @ts-ignore
                    errors?.[fieldsName]?.unitAmount
                )}
                helperText={
                    //@ts-ignore
                    touched?.[fieldsName]?.unitAmount &&
                    // @ts-ignore
                    errors?.[fieldsName]?.unitAmount
                }
                type="number"
            />

            <TextField
                select
                label={"Currency"}
                placeholder={"Currency"}
                {...getFieldProps(`${fieldsName}.currency`)}
                SelectProps={{ native: true }}
            >
                <option value=""></option>
                {CURRENCIES_LIST.map(({code, name: currencyName}) => {
                    return (
                        <option key={code} value={code}>
                            {currencyName}
                        </option>
                    );
                })}
            </TextField>

            <TextField
                fullWidth
                label={`Quantity`}
                {...getFieldProps(`${fieldsName}.quantity`)}
                error={Boolean(
                    //@ts-ignore
                    touched?.[fieldsName]?.quantity &&
                    // @ts-ignore
                    errors?.[fieldsName]?.quantity
                )}
                helperText={
                    //@ts-ignore
                    touched?.[fieldsName]?.quantity &&
                    // @ts-ignore
                    errors?.[fieldsName]?.quantity
                }
                type="number"
            />

            {
                Object.keys(name).map((locale) => {
                    return (
                        <TextField
                            key={locale}
                            fullWidth
                            label={`Link Name in ${locale}`}
                            {...getFieldProps(`${fieldsName}.name.${locale}`)}
                            error={Boolean(
                                //@ts-ignore
                                touched?.[fieldsName]?.name?.[locale] &&
                                // @ts-ignore
                                errors?.[fieldsName]?.name?.[locale]
                            )}
                            helperText={
                                //@ts-ignore
                                touched?.[fieldsName]?.name?.[locale] &&
                                // @ts-ignore
                                errors?.[fieldsName]?.name?.[locale]
                            }
                            type="text"
                        />)

                })
            }
            {
                Object.keys(description).map((locale) => {
                    return (
                        <TextField
                            key={locale}
                            fullWidth
                            label={`Link description in ${locale}`}
                            {...getFieldProps(`${fieldsName}.description.${locale}`)}
                            error={Boolean(
                                //@ts-ignore
                                touched?.[fieldsName]?.description?.[locale] &&
                                // @ts-ignore
                                errors?.[fieldsName]?.description?.[locale]
                            )}
                            helperText={
                                //@ts-ignore
                                touched?.[fieldsName]?.description?.[locale] &&
                                // @ts-ignore
                                errors?.[fieldsName]?.description?.[locale]
                            }
                            type="text"
                            multiline
                        />)

                })
            }

            {editing && <FormControlLabel
                control={
                    <Switch
                        onChange={(event) =>
                            setFieldValue(
                                `${fieldsName}.reTranslateData`,
                                event.target.checked
                            )
                        }
                        checked={Boolean(
                            getFieldProps(
                                `${fieldsName}.reTranslateData`
                            ).value
                        )}
                    />
                }
                labelPlacement="start"
                label="Retranslate name and description"
                sx={{ mt: 5 }}
            /> }
        </Stack>
    )
}
